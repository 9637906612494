body {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'%3E%3Cdefs%3E%3Crect fill='none' stroke-width='100' id='a' x='-400' y='-300' width='800' height='600'/%3E%3C/defs%3E%3Cg style='transform-origin:center'%3E%3Cg transform='' style='transform-origin:center'%3E%3Cg transform='rotate(-155.2 0 0)' style='transform-origin:center'%3E%3Cg transform='translate(1000 750)'%3E%3Cuse stroke='%23000' href='%23a' transform='rotate(9.7 0 0) scale(1.097)'/%3E%3Cuse stroke='%23000011' href='%23a' transform='rotate(19.4 0 0) scale(1.194)'/%3E%3Cuse stroke='%23000022' href='%23a' transform='rotate(29.1 0 0) scale(1.291)'/%3E%3Cuse stroke='%23000033' href='%23a' transform='rotate(38.8 0 0) scale(1.388)'/%3E%3Cuse stroke='%23000044' href='%23a' transform='rotate(48.5 0 0) scale(1.485)'/%3E%3Cuse stroke='%23000055' href='%23a' transform='rotate(58.2 0 0) scale(1.582)'/%3E%3Cuse stroke='%23000066' href='%23a' transform='rotate(67.9 0 0) scale(1.679)'/%3E%3Cuse stroke='%23000077' href='%23a' transform='rotate(77.6 0 0) scale(1.776)'/%3E%3Cuse stroke='%23000088' href='%23a' transform='rotate(87.3 0 0) scale(1.873)'/%3E%3Cuse stroke='%23000099' href='%23a' transform='rotate(97 0 0) scale(1.97)'/%3E%3Cuse stroke='%230000aa' href='%23a' transform='rotate(106.7 0 0) scale(2.067)'/%3E%3Cuse stroke='%230000bb' href='%23a' transform='rotate(116.4 0 0) scale(2.164)'/%3E%3Cuse stroke='%230000cc' href='%23a' transform='rotate(126.1 0 0) scale(2.261)'/%3E%3Cuse stroke='%230000dd' href='%23a' transform='rotate(135.8 0 0) scale(2.358)'/%3E%3Cuse stroke='%230000ee' href='%23a' transform='rotate(145.5 0 0) scale(2.455)'/%3E%3Cuse stroke='%2300F' href='%23a' transform='rotate(155.2 0 0) scale(2.552)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

div{
  text-align: center;
}

p, h1{
  display: none;
  color:aliceblue
}

h2 {
  color:aliceblue;
  margin-top: 9rem;
}

button{
  display: none;
  margin: auto;
  height: 2.4rem;
  margin-bottom: 3rem;
}


.dinheiro {
  width: 60%;
  display: none;
  animation: float 3s ease-in-out infinite;
}
@keyframes float {
  0%, 100%{
    transform: translate(0);
  }
  50% {
    transform: translateY(-15px)
  }
}

.gif {
  width: 80%;
}


.ativo{
  display: block;
  margin: 1rem auto;
}

.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

@media (min-width: 768px){
  .dinheiro {
    width: 30%;
  }
  .gif {
    width: 40%;
  }
  h2 {
    color:aliceblue;
    margin-top: 2rem;
  }
}